import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import PropTypes from 'prop-types';

export function LabelToolTip({ tooltip, testId }) {
  let label;
  if (typeof tooltip === 'object') {
    label = tooltip.props.children.toString().replaceAll('[object Object],', '');
  } else {
    label = tooltip;
  }
  return (
    <Tooltip title={tooltip} sx={{ fontSize: '16px' }} aria-label={label}>
      <InfoIcon fontSize="inherit" data-id={testId} sx={{ bottom: '-2px', position: 'relative' }} className="tool-tip" />
    </Tooltip>
  );
}

LabelToolTip.propTypes = {
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  testId: PropTypes.string,
};

LabelToolTip.defaultProps = {
  tooltip: null,
  testId: '',
};
