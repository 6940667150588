import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Container } from '@mui/material';
import { store } from '../../store';
import ConsultantDataLoadingService from '../../services/ConsultantDataLoadingService';
import Header from '../../components/Header';
import CircularIndeterminate from '../../components/Spinner';
import ConsultantTable from './consultantTable';

function ConsultantContainer() {
  return (
    <div>
      <ReduxProvider store={store}>
        <ConsultantDataLoadingService />
        <div className="container-app">
          <Header />
          <Container maxWidth="lg" className="accordion-container">
            <div>
              <CircularIndeterminate />
            </div>
            <div>
              <ConsultantTable />
            </div>
          </Container>
        </div>
      </ReduxProvider>
    </div>
  );
}

export default ConsultantContainer;
