import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

function CircularIndeterminate() {
  const loadStatusSelector = (state) => state.consultantPortal.status;
  const loadStatus = useSelector(loadStatusSelector);
  if (loadStatus === 'loading') {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} data-testid="network-call-spinner">
        <CircularProgress />
      </Box>
    );
  }
}

export default CircularIndeterminate;
