import React from 'react';
import Button from '@mui/material/Button';
import { useMsal, useAccount } from '@azure/msal-react';

function LogoutButton() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleLogOut = () => {
    instance.logoutRedirect({ account });
  };

  return (
    <Button color="success" onClick={handleLogOut}>Logout</Button>
  );
}

export default LogoutButton;
