import React from 'react';
import Button from '@mui/material/Button';
import { useMsal } from '@azure/msal-react';

function LoginButton() {
  const { instance } = useMsal();

  const handleLogIn = () => {
    instance.loginRedirect({
      scopes: ['user.read', 'email'],
    });
  };

  return (
    <Button variant="contained" color="success" size="large" onClick={handleLogIn}>Login</Button>
  );
}

export default LoginButton;
