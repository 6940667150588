import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LabelToolTip } from '../../components/ToolTip';
import { tooltips } from '../../consts';
import '../consultant/consultantBio.css';

function CurrentEngagement() {
  const {
    CUR_ENGAGE_CLIENT_MANAGER,
    CUR_ENGAGE_TEAM_MANAGER,
    CUR_ENGAGE_START_DATE,
    CUR_ENGAGE_END_DATE,
  } = tooltips;
  const consultantBioSelector = (state) => state.consultantPortal.consultantInfo;
  const consultantBio = useSelector(consultantBioSelector);
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="accordion-container" data-testid="current-engagement-component">
      <Accordion expanded={expanded === true} onChange={handleChange(!expanded)} data-testid="current-engagement-accordion-button">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="current-engagement-content"
          id="current-engagement-content"
          className="header-row"
          data-testid="current-engagement-content"
        >
          <b>Current Engagement</b>
        </AccordionSummary>
        <AccordionDetails>
          <table className="consultant-panel-table verticalTable">
            <tbody>
              <tr>
                <td data-testid="current-engagement-client-label">
                  Client
                </td>
                <td data-testid="current-engagement-client-value">
                  {consultantBio?.primaryActivity?.account?.name}
                </td>
              </tr>
              <tr>
                <td data-testid="current-engagement-name-label">
                  Engagement Name
                </td>
                <td data-testid="current-engagement-name-value">
                  {consultantBio?.primaryActivity.engagementName}
                </td>
              </tr>
              <tr>
                <td data-testid="current-engagement-team-manager-label">
                  Team Manager
                  {' '}
                  <LabelToolTip tooltip={CUR_ENGAGE_TEAM_MANAGER} testId="current-engagement-team-manager-tooltip" />
                </td>
                <td data-testid="current-engagement-team-manager-value">
                  {consultantBio?.primaryActivity.teamManager.name}
                </td>
              </tr>
              <tr>
                <td data-testid="current-engagement-client-manager-label">
                  Client Manager
                  {' '}
                  <LabelToolTip tooltip={CUR_ENGAGE_CLIENT_MANAGER} testId="current-engagement-client-manager-tooltip" />
                </td>
                <td data-testid="current-engagement-client-manager-value">
                  {consultantBio?.primaryActivity.clientManager.name}
                </td>
              </tr>
              <tr>
                <td data-testid="current-engagement-start-date-label">
                  Contractual Start Date
                  {' '}
                  <LabelToolTip tooltip={CUR_ENGAGE_START_DATE} testId="current-engagement-start-date-tooltip" />
                </td>
                <td data-testid="current-engagement-start-date-value">
                  {consultantBio?.primaryActivity.startDate}
                </td>
              </tr>
              <tr>
                <td data-testid="current-engagement-end-date-label">
                  Contractual End Date
                  {' '}
                  <LabelToolTip tooltip={CUR_ENGAGE_END_DATE} testId="current-engagement-end-date-tooltip" />
                </td>
                <td data-testid="current-engagement-end-date-value">
                  {consultantBio?.primaryActivity.endDate}
                </td>
              </tr>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CurrentEngagement;
