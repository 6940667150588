import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LabelToolTip } from '../../components/ToolTip';
import { tooltips } from '../../consts';
import './consultantBio.css';

function ConsultantBio() {
  const {
    BIO_PRIMARY_ROLE_INFOTIP,
    BIO_LOS_LEADER_INFOTIP,
    BIO_REGION_INFOTIP,
    BIO_ROLES_INFOTIP,
    BIO_SKILLSET_INFOTIP,
    BIO_TITLE_INFOTIP,
  } = tooltips;

  const consultantBioSelector = (state) => state.consultantPortal.consultantInfo;
  const consultantBio = useSelector(consultantBioSelector);
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className="accordion-container" data-testid="consultant-bio-component">
      <Accordion expanded={expanded === true} onChange={handleChange(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="bio-panel-summary"
          id="bio-panel-summary"
          data-testid="bio-panel-button"
          className="header-row"
        >
          <b>Bio</b>
        </AccordionSummary>
        <AccordionDetails>
          <table className="consultant-panel-table verticalTable">
            <tbody>
              <tr>
                <td data-testid="bio-primary-los-label">
                  Primary LOS
                </td>
                <td data-testid="bio-primary-los-value">
                  {consultantBio?.primaryLos}
                </td>
              </tr>
              <tr>
                <td data-testid="bio-region-label">
                  Region
                  {' '}
                  <LabelToolTip tooltip={BIO_REGION_INFOTIP} testId="bio-region-tooltip" />
                </td>
                <td data-testid="bio-region-value">
                  {consultantBio?.region}
                </td>
              </tr>
              <tr>
                <td data-testid="bio-title-label">
                  Title
                  {' '}
                  <LabelToolTip tooltip={BIO_TITLE_INFOTIP} testId="bio-title-tooltip" />
                </td>
                <td data-testid="bio-title-value">
                  {consultantBio?.title}
                </td>
              </tr>
              <tr>
                <td data-testid="bio-primary-role-label">
                  Primary Role
                  {' '}
                  <LabelToolTip tooltip={BIO_PRIMARY_ROLE_INFOTIP} testId="bio-primary-role-tooltip" />
                </td>
                <td data-testid="bio-primary-role-value">
                  {consultantBio?.primaryRole}
                </td>
              </tr>
              <tr>
                <td data-testid="bio-roles-label">
                  All Roles
                  {' '}
                  <LabelToolTip tooltip={BIO_ROLES_INFOTIP} testId="bio-roles-tooltip" />
                </td>
                <td data-testid="bio-roles-value">
                  {consultantBio?.roles}
                </td>
              </tr>
              <tr>
                <td data-testid="bio-los-leader-label">
                  LOS Leader
                  {' '}
                  <LabelToolTip tooltip={BIO_LOS_LEADER_INFOTIP} testId="bio-los-leader-tooltip" />
                </td>
                <td data-testid="bio-los-leader-value">
                  {consultantBio?.losLeader.name}
                </td>
              </tr>
              <tr>
                <td data-testid="bio-skillset-label">
                  Skillset
                  {' '}
                  <LabelToolTip tooltip={BIO_SKILLSET_INFOTIP} testId="bio-skillset-tooltip" />
                </td>
                <td data-testid="bio-skillset-value">
                  {consultantBio?.skillset}
                </td>
              </tr>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ConsultantBio;
