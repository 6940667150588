import * as React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { useSelector } from 'react-redux';
import { Card } from '@mui/material';
import AboutMe from '../aboutme/AboutMe';
import ConsultantBio from '../consultant/ConsultantBio';
import CurrentEngagement from '../currentEngagement/CurrentEngagement';
import UpcomingOpportunities from '../upcomingOpportunities/UpcomingOpportunities';
import { errors } from '../../consts';

function ConsultantTable() {
  const loadStatusSelector = (state) => state.consultantPortal.status;
  const loadStatus = useSelector(loadStatusSelector);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const emailLogged = account?.idTokenClaims?.email || false;
  const responseNotFound = emailLogged ? `${errors.NOT_FOUND_BY_EMAIL} ${emailLogged}. ${errors.REACH_LOS_MSG}` : `${errors.NOT_FOUND_BY_EMAIL}. ${errors.REACH_LOS_MSG}`;
  const errorFailed = errors.ERROR_FAILED;
  if (loadStatus === 'idle') {
    return (
      <>
        <h1 className="about-me-header" data-testid="about-me-header">
          About Me
        </h1>
        <Card className="box-raised" raised>
          <AboutMe />
          <ConsultantBio />
          <CurrentEngagement />
          <UpcomingOpportunities />
        </Card>
      </>
    );
  } if (loadStatus === 'error') {
    // eslint-disable-next-line max-len
    return (<h1>{errorFailed}</h1>);
  } if (loadStatus === 'notFound') {
    // eslint-disable-next-line max-len
    return (<h1>{responseNotFound}</h1>);
  }
}

export default ConsultantTable;
