import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_AD_AUTHORITY,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        console.log(message);
      },
      logLevel: process.env.AZURE_AD_LOG_LEVEL,
    },
  },
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App msalInstance={pca} />
    </BrowserRouter>
  </React.StrictMode>,
);
