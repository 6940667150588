import React from 'react';
import { useSelector } from 'react-redux';
import './aboutMe.css';

function AboutMe() {
  const consultantBioSelector = (state) => state.consultantPortal.consultantInfo;
  const consultantBio = useSelector(consultantBioSelector);
  return (
    <div className="about-me-info" data-testid="about-me-component">
      <h2 className="about-me-name" data-testid="about-me-name">
        {consultantBio?.name}
      </h2>
      <div className="about-me-status">
        <span data-testid="about-me-status-label">Status:</span>
        {' '}
        <span data-testid="about-me-status">{consultantBio?.activityStatus}</span>
      </div>
    </div>
  );
}

export default AboutMe;
