import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpcomingOpportunitiesTable from './UpcomingOpportunitiesTable';

function UpcomingOpportunities() {
  const consultantBioSelector = (state) => state.consultantPortal.consultantInfo;
  const consultantBio = useSelector(consultantBioSelector);
  const [expanded, setExpanded] = useState(true);
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="accordion-container" data-testid="upcoming-opportunities-component">
      <Accordion expanded={expanded === true} onChange={handleChange(!expanded)} data-testid="upcoming-opportunities-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="upcoming-opportunities-summary"
          id="upcoming-opportunities-summary"
          data-testid="upcoming-opportunities-button"
          className="header-row"
        >
          <b>Upcoming Opportunities</b>
        </AccordionSummary>
        <AccordionDetails>
          <UpcomingOpportunitiesTable targets={consultantBio?.targets} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default UpcomingOpportunities;
