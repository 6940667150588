import React from 'react';
import './Login.css';
import LoginButton from './LoginButton';
// import { useEffect } from "react";
// import { useState } from "react";

function Login() {
  return (
    <div className="login-container">
      <div className="logo-container">
        <img src="images/daugherty_long_transparent.png" className="logo" alt="daugherty-logo" />
      </div>
      <div className="text-container">
        <h3>Welcome to Daugherty Consultant Portal. Please login using the below button</h3>
      </div>
      <div className="login-button-container">
        <LoginButton />
      </div>
    </div>
  );
}

export default Login;
