import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
// import InfoIcon from '@mui/icons-material/Info';
// import Tooltip from '@mui/material/Tooltip';
import { tooltips } from '../../consts';
import { LabelToolTip } from '../../components/ToolTip';

const StyledTableHeaderRow = styled(TableRow)(() => ({
  td: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontWeight: 'bold',
    fontSize: '.75rem',
  },
}));

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function TableCellWToolTip({
  heading,
  tooltip,
  sx,
  testId,
}) {
  return (
    <TableCell sx={sx}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {heading}
        <LabelToolTip tooltip={tooltip} testId={testId} />
      </div>
    </TableCell>
  );
}

TableCellWToolTip.propTypes = {
  heading: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  sx: PropTypes.objectOf(PropTypes.string),
  testId: PropTypes.string,
};

TableCellWToolTip.defaultProps = {
  heading: '',
  tooltip: '',
  sx: undefined,
  testId: '',
};

function UpcomingOpportunitiesTable({ targets }) {
  const {
    UPCOMING_OPPORTUNITIES_POTENTIAL_START_INFOTIP,
    UPCOMING_OPPORTUNITIES_OWNER_INFOTIP,
  } = tooltips;

  return (
    <TableContainer>
      <Table
        aria-label="table outlining your uppcoming opportunites"
        data-testid="upcoming-opportunities-table"
      >
        <thead>
          <StyledTableHeaderRow data-testid="upcoming-opportunities-table-heading-row">
            <TableCell data-testid="upcoming-opportunities-table-client-label">Client</TableCell>
            <TableCell sx={{ minWidth: '150px' }} data-testid="upcoming-opportunities-table-opportunity-name-label">
              Opportunity Name
            </TableCell>
            <TableCellWToolTip
              heading="Stage"
              testId="upcoming-opportunities-table-stage-label"
              tooltip={(
                <span>
                  The Stage of an opportunity identifies where we are in
                  the process of developing the opportunity with the client.
                  <br />
                  - 3 Mutual Qualification
                  <br />
                  - 4 Propose
                  <br />
                  - 5 Contract Negotiations
                  <br />
                  - 6 Initiate Engagement
                  <br />
                  - 7 Closed Won
                </span>
              )}
            />
            <TableCellWToolTip
              heading="Status"
              testId="upcoming-opportunities-table-status-label"
              tooltip={(
                <span>
                  The opportunity status identifies where you are in
                  the process of getting engaged for this opportunity.
                  <br />
                  <br />
                  <b>Targeted</b>
                  {' '}
                  - You are being considered for this opportunity by LOS and client leaders.
                  Please understand that targets can change rapidly as opportunities develop
                  so do not be surprised if you are targeted one day but not the next.
                  When targeted for an opportunity you can expect to have conversations with
                  Daugherty client managers, other Daugherty teammates,
                  and even have a client conversation
                  <br />
                  <br />
                  <b>Reserved</b>
                  {' '}
                  - You are being reserved for this opportunity while client leaders
                  continue to develop the opportunity with the client.
                  <br />
                  <br />
                  <b>Confirmed</b>
                  {' '}
                  - Client has consented to start project. You will typically be going through
                  on-boarding activities and receive hardware before your start date.
                  Do not hesitate to reach out to your LOS leader
                  or the opportunity owner for updates.
                </span>
              )}
            />
            <TableCellWToolTip
              sx={{ minWidth: '111px' }}
              heading="Potential Start"
              testId="upcoming-opportunities-table-potential-start-label"
              tooltip={UPCOMING_OPPORTUNITIES_POTENTIAL_START_INFOTIP}
            />
            <TableCellWToolTip
              heading="Owner"
              testId="upcoming-opportunities-table-owner-label"
              tooltip={UPCOMING_OPPORTUNITIES_OWNER_INFOTIP}
            />
          </StyledTableHeaderRow>
        </thead>
        <TableBody data-testid="upcoming-opportunities-table-body">
          {targets.map((row, index) => (
            <StyledTableBodyRow
              key={row.id}
              data-testid={`upcoming-opportunities-table-body-row-${index}`}
            >
              <TableCell data-testid={`upcoming-opportunities-table-client-value-${index}`}>{row?.opportunity?.account?.name}</TableCell>
              <TableCell data-testid={`upcoming-opportunities-table-opportunity-name-value-${index}`}>{row?.opportunity?.name}</TableCell>
              <TableCell data-testid={`upcoming-opportunities-table-stage-value-${index}`}>{row?.opportunity?.stage}</TableCell>
              <TableCell data-testid={`upcoming-opportunities-table-status-value-${index}`}>{row?.engagementStatus}</TableCell>
              <TableCell data-testid={`upcoming-opportunities-table-potential-start-value-${index}`}>{row?.potentialStart}</TableCell>
              <TableCell data-testid={`upcoming-opportunities-table-owner-value-${index}`}>{row.opportunity?.owner?.name}</TableCell>
            </StyledTableBodyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

UpcomingOpportunitiesTable.propTypes = {
  targets: PropTypes.arrayOf(PropTypes.shape),
};

UpcomingOpportunitiesTable.defaultProps = {
  targets: [],
};

export default UpcomingOpportunitiesTable;
