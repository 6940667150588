import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import LogoutButton from '../features/logout/LogoutButton';

function Header() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between App-header">
        <div>
          <img src="images/daugherty_long_transparent.png" className="App-logo" alt="daugherty-logo" />
        </div>
        <div>
          <Button variant="contained" color="success" size="large" onClick={handleClickOpen}>Logout</Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to Logout?
            </DialogTitle>
            <DialogActions>
              <Button color="success" onClick={handleClose}>Close</Button>
              <LogoutButton onClick={handleClose} autoFocus>Logout</LogoutButton>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Header;
