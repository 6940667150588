/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
};

export const consultantDataSlice = createSlice({
  name: 'consultantInfo',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setConsultantData: (state, action) => {
      state.consultantInfo = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const {
  setConsultantData,
  setStatus,
} = consultantDataSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   // const currentValue = selectConsultantBio(getState());
//   // if (currentValue % 2 === 1) {
//   //   dispatch(incrementByAmount(amount));
//   // }
// };

export default consultantDataSlice.reducer;
