import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { PublicClientApplication } from '@azure/msal-browser';
import PropTypes from 'prop-types';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { MsalProvider, UnauthenticatedTemplate, AuthenticatedTemplate } from '@azure/msal-react';
import ConsultantContainer from './features/consultantContainer/consultantContainer';
import Login from './features/login/Login';
import NotFound from './features/notFound/NotFound';

function App({ msalInstance }) {
  return (
    <div>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<ConsultantContainer />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </div>
  );
}

App.propTypes = {
  msalInstance: PropTypes.objectOf(PublicClientApplication).isRequired,
};

export default App;
